<template>
  <svg viewBox="0 0 23 23" :width="size">
    <switch><g><path d="M6.6 4.5c.5 0 .8-.4.8-1V1c0-.5-.4-1-.8-1s-.8.4-.8 1v2.5c0 .5.4 1 .8 1zM16.3 4.5c.5 0 .8-.4.8-1V1c0-.5-.4-1-.8-1-.5 0-.8.4-.8 1v2.5c0 .5.4 1 .8 1zM9.6 3.2h3.3c.5 0 1-.4 1-1 0-.5-.4-1-1-1H9.6c-.5 0-1 .4-1 1s.4 1 1 1z"/><path d="M19.6 1.2H19c-.5 0-1 .4-1 1 0 .5.4 1 1 1h.6c.5 0 .9.4.9.9v1.7H2V4.1c0-.5.4-.9.9-.9h.6c.5 0 1-.4 1-1 0-.5-.4-1-1-1h-.7C1.3 1.2 0 2.5 0 4.1v15.7c0 1.6 1.3 2.8 2.8 2.8h16.8c1.6 0 2.8-1.3 2.8-2.8V4.1c0-1.6-1.2-2.9-2.8-2.9zM2 7.8h18.5v12c0 .5-.4.9-.9.9H2.8c-.5 0-.9-.4-.9-.9v-12z"/><path d="M7.4 18.4c.5.4 1.1.5 1.7.5.8 0 1.5-.3 2.1-.9.6-.6.9-1.3.9-2.1 0-.5-.1-1-.4-1.5-.2-.4-.5-.7-.9-1 .3-.4.5-.9.5-1.4 0-.6-.2-1.2-.7-1.6-.4-.4-1-.7-1.6-.7-.5 0-.9.1-1.3.4-.4.3-.7.6-.8 1.1-.1.3-.1.5-.1.7v.1h1.4c0-.1 0-.2.1-.3.1-.2.2-.3.3-.4.2-.1.3-.2.5-.2s.4.1.6.2c.2.3.3.5.3.7 0 .2-.1.4-.2.6-.2.2-.4.2-.6.2H8.1v1.4h1.1c.5 0 .8.2 1.2.5.3.3.5.7.5 1.2s-.2.8-.5 1.2-.7.5-1.2.5c-.3 0-.7-.1-.9-.3-.3-.2-.5-.4-.6-.7-.1-.2-.1-.4-.1-.6v-.1H6.1v.1c0 .4.1.7.2 1.1.2.5.6.9 1.1 1.3zM14.8 11.3c.1.1.2.2.2.4v7.2h1.4v-7.2c0-.5-.2-1-.6-1.4-.4-.4-.8-.6-1.4-.6h-.8v1.4h.8c.2 0 .3.1.4.2z"/></g></switch>
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      default: 19
    }
  }
};
</script>
